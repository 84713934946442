const faq = () => {
  let acc = document.querySelectorAll('.accordion');
  let i;
  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener('click', function (event) {
      event.stopImmediatePropagation();
      for (let j = 0; j < acc.length; j++) {
        if (acc[j] !== this) {
          acc[j].classList.remove('active');
          let panel = acc[j].nextElementSibling;
          panel.style.maxHeight = null;
          let line = acc[j].nextElementSibling.nextElementSibling;
          if (line && line.classList.contains('line')) {
            line.style.marginTop = null;
          }
        }
      }

      this.classList.toggle('active');
      let panel = this.nextElementSibling;
      if (this.classList.contains('active')) {
        panel.style.maxHeight = panel.scrollHeight + 'px';
        let line = this.nextElementSibling.nextElementSibling;
        if (line && line.classList.contains('line')) {
          line.style.marginTop = '10px';
        }
      } else {
        panel.style.maxHeight = null;
        let line = this.nextElementSibling.nextElementSibling;
        if (line && line.classList.contains('line')) {
          line.style.marginTop = null;
        }
      }
    });
  }
};


document.addEventListener('DOMContentLoaded', async () => {
  faq();
});
